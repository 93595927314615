<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.ControlService.banner.title') }}</p>
                        <div class="banner-txt">{{ $t('Public.ControlService.banner.text') }}</div>
                    </div>
                </transition>
            </div>
            <div class="content">
                <p class="detail_title">{{ $t('Public.ControlService.control.title') }}</p>
                <div class="flex contorl_detail">
                    <div class="detail_img"><img src="../../public/images/service/control/control_car.png" /></div>
                    <ul>
                        <li v-for="(item, index) in $t('Public.ControlService.control.list')" :key="index" @mouseover="contorlActive = index" @mouseout="contorlActive = 99">
                            <div class="contorl_detail_title">
                                <div class="control_icon">
                                    <img v-if="contorlActive == index" :src="require('../../public/images/service/control/control_icon_checked' + (index + 1) + '.png')" />
                                    <img v-else :src="require('../../public/images/service/control/control_icon' + (index + 1) + '.png')" />
                                </div>
                                <p>{{ item.title }}</p>
                            </div>
                            <span>{{ item.text }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="galileo">
                <p class="detail_title">Galileo-Mobility Platform</p>
                <p class="detail_title" v-if="$i18n.locale == 'CN'">驾安安平台</p>
                <div class="galileo_introduce" v-show="0">
                    {{ $t('Public.UBIService.galileo.galileoIntro') }}
                </div>
                <div class="galileo_img">
                    <img v-if="$i18n.locale == 'CN'" src="../../public/images/service/ubi/galileo_cn.png" />
                    <img v-else src="../../public/images/service/ubi/galileo_en.png" />
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'ControlService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false,
            contorlActive: 99
        };
    },
    methods: {},
    mounted() {
        this.show = true;
    }
};
</script>
<style scoped>
.contorl_detail {
    margin-top: 1rem;
}
.detail_img {
    width: 4rem;
}
.contorl_detail li {
    margin-bottom: 0.45rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #666666;
    width: 7.3rem;
}
.contorl_detail li span {
    line-height: 1.5;
    display: inline-block;
    padding-left: 0.6rem;
}
.contorl_detail_title {
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.1rem;
}
.control_icon {
    width: 0.4rem;
    margin-right: 0.2rem;
}
.banner {
    width: 100%;
    height: 5.76rem;
    padding: 1.8rem 3.6rem 0;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/service/control/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 2;
    background-color: #31575c;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.28rem;
}
.banner-txt {
    text-align: justify;
}
.content {
    padding: 1rem 3.6rem 1rem;
    color: #333;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}

.galileo {
    padding: 1rem 3.6rem 2rem;
}
.galileo_introduce {
    line-height: 2;
    margin: 0.73rem 0 0.7rem;
    font-size: 0.2rem;
    font-weight: 400;
    color: #333333;
}
.galileo_img {
    width: 11.55rem;
    margin: 0 auto;
}

.galileo .detail_title {
    color: #333333;
}
</style>
